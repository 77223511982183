import React, { useEffect } from "react";
import { SourceSelection } from "@swbc/swivel-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Title from "../../components/Title";
import { clearLoanToEdit, setLoanToEdit } from "../../store/loans/loans.slice";
import { ACCOUNT, LOAN } from "../../utils/constants/constants";
import { getAccountLastFour } from "../../utils/helpers/handleAccounts";

const NonValidationAddAccount = () => {
  // Named selectors
  const loanState = (state) => state.loans;
  const institutionState = (state) => state.institution;

  // Pass in named selectors and gets state from redux
  const { list: allLoans } = useSelector(loanState);
  const { config } = useSelector(institutionState);

  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addOrEditRoute = "/add-or-edit-account";
  const hasAccountApplyToTypes = config?.no_validation_file?.account_apply_to_types?.length > 0;
  const accountApplyToTypes = config?.no_validation_file?.account_apply_to_types;
  const accounts = allLoans?.filter((item) => accountApplyToTypes?.includes(item.type));
  const loans = allLoans?.filter((item) => !accountApplyToTypes?.includes(item.type));

  const accountList = accounts?.map((item) => {
    return {
      id: item?.id,
      groupType: ACCOUNT,
      type: item?.type,
      primaryText: item?.type,
      secondaryText: getAccountLastFour(item?.number),
      nameOnAccount: item?.name_on_account,
      number: item?.number
    };
  });

  const loanList = loans?.map((item) => {
    return {
      id: item?.id,
      groupType: LOAN,
      type: item?.type,
      primaryText: item?.type,
      secondaryText: getAccountLastFour(item?.number),
      nameOnAccount: item?.name_on_account,
      number: item?.number
    };
  });

  const allLoansList = allLoans?.map((item) => {
    return {
      id: item?.id,
      groupType: LOAN,
      type: item?.type,
      primaryText: item?.type,
      secondaryText: getAccountLastFour(item?.number),
      nameOnAccount: item?.name_on_account,
      number: item?.number
    };
  });

  const setAccountToEdit = (loan) => {
    dispatch(setLoanToEdit(loan));
    navigate(addOrEditRoute, { state: { type: loan.groupType, action: "Edit" } });
  };

  const addAccount = () => {
    navigate(addOrEditRoute, { state: { type: ACCOUNT, action: "Add" } });
  };

  const addLoan = () => {
    navigate(addOrEditRoute, { state: { type: LOAN, action: "Add" } });
  };

  useEffect(() => {
    dispatch(clearLoanToEdit());
  }, [navigate]);

  const selections = [
    {
      id: 1,
      type: "loan",
      label: "Loan",
      title: "Loan",
      infoTitle: "No loans to display",
      infoMessage: "Add a new loan to get started",
      enabled: true
    },
    {
      id: 2,
      type: "account",
      label: "Account",
      title: "Account",
      infoTitle: "No account to display",
      infoMessage: "Add a new account to get started",
      enabled: hasAccountApplyToTypes
    }
  ];

  return (
    <>
      <Title title="Add or Edit Accounts" />
      <SourceSelection
        selections={selections}
        accountList={hasAccountApplyToTypes ? accountList : null}
        loanList={hasAccountApplyToTypes ? loanList : allLoansList}
        onEdit={setAccountToEdit}
        onClickAddAccount={addAccount}
        onClickAddLoan={addLoan}
      />
    </>
  );
};

export default NonValidationAddAccount;
