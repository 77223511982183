import React, { useState, useEffect, useMemo, useContext } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import TimeoutModal from "./components/modals/TimeoutModal";
import NavigationManager from "./components/navigationHandlers/NavigationManager";
import { Layout } from "./layout";
import ErrorPage from "./pages/Error/ErrorPage";
import { useGoogleAnalytics } from "./services/analytics/hooks/useGoogleAnalytics";
import { SwivelContext } from "./services/sdk/context/SwivelProvider";
import { setIsTimeoutModalOpen, setIsTimeoutTimerActive } from "./store/activity/activity.slice";
import { setAuthToken, getUser } from "./store/auth/auth.slice";
import { getInstitution, getInstitutionDetails, setLoadingState } from "./store/institution/institution.slice";
import { getMaintenance } from "./store/maintenance/maintenance.slice";
import { setErrorPageLoaded } from "./store/navhistory/navhistory.slice";
import { clearPaymentDetails, clearCardForm } from "./store/payment/makePayment.slice";
import { ONE_SECOND, LOADING_PAGE, LOADING_FI_DATA, LOADING_MW_DATA } from "./utils/constants/constants";
import { swivelColors } from "./utils/data/swivelColors";
import { isUuidValid } from "./utils/helpers/uuidHelpers";
import { useMaterialTheme } from "./utils/hooks/useMaterialTheme";

const {
  REACT_APP_MODAL_REFRESH_TIMER,
  REACT_APP_DIGITAL_WALLETS_PATH
} = process.env;

const App = () => {
  // Named selectors
  const activityState = (state) => state.activity;
  const institutionState = (state) => state.institution;
  const maintenanceState = (state) => state.maintenance;
  const authState = (state) => state.auth;
  const paymentState = (state) => state.payment;

  // Pass in named selectors and gets state from redux
  const { timeoutModal } = useSelector(activityState);
  const { isTimerActive: isTimeoutTimerActive, isOpen: isTimeoutModalOpen } = timeoutModal;
  const { config, details, isLoading: isInstitutionLoading, institutionError, isInstitutionDetailsLoading } = useSelector(institutionState);
  const { isLoading: isMaintenanceLoading } = useSelector(maintenanceState);
  const { timeoutMessage, shouldClearBreadcrumbs, user } = useSelector(authState);
  const { isPaymentSuccessful } = useSelector(paymentState);

  // Local States
  const [isSiteLoading, setSiteIsLoading] = useState(true);
  const [institutionLoaded, setInstitutionLoaded] = useState(false);
  const [institutionDetailsLoaded, setInstitutionDetailsLoaded] = useState(false);
  const [maintenanceCallSuccess, setMaintenanceCallSuccess] = useState(false);
  const [redirectionPath, setRedirectionPath] = useState("");

  // these states deal with navigation based on institution and maintenance api calls
  const [loadingMessage, setLoadingMessage] = useState(LOADING_PAGE);

  // Context
  const { embedded } = useContext(SwivelContext);

  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { initialize, pageView, actions, gaImplementationMethodCategory, trackEvent } = useGoogleAnalytics();
  const { swivelize } = useFlags();

  // clear paymentform if navigated from other routes mentioned in routePaths
  const checkClearPaymentRoute = useMemo(() => {
    const routePaths = ["/pending-payments", "/review-payment", "/link-bank-account", "/make-payment", "/payment-confirmed"];
    const checkPath = routePaths.includes(location.pathname);
    return checkPath;
  }, [location.pathname]);

  useEffect(() => {
    if (!checkClearPaymentRoute) {
      dispatch(clearPaymentDetails());
      dispatch(clearCardForm());
    }
    return () => checkClearPaymentRoute;
  }, [dispatch, checkClearPaymentRoute]);

  const querySearchParamInsensitive = (param) => {
    const params = new URLSearchParams(location.search.toString().toLowerCase());
    return params.get(param.toString().toLowerCase());
  };
  const querySearchParam = (param) => {
    const params = new URLSearchParams(location.search.toString());
    return params.get(param.toString());
  };

  const queryParamId = querySearchParamInsensitive("institutionId");
  const institutionId = queryParamId || config?.id;
  const userId = querySearchParamInsensitive("userId");
  const queryToken = querySearchParam("token");
  const isFromSSO = querySearchParamInsensitive("fromSSO") === "true";
  const query = `?institutionId=${institutionId}`;
  const paths = {
    express: `/express-pay${query}`,
    login: `/login${query}`,
    ssoTimeout: "/sso-timeout"
  };

  const validatedConfig = swivelize ? swivelColors : config;

  const currentLocation = location.pathname.toLocaleLowerCase();
  const isOnConfirmationPage = currentLocation.includes("/payment-confirmed");
  const isOnAccountsPage = currentLocation.includes("/accounts");
  const isOnDeepLinkPage = currentLocation.includes("/deeplink-login");
  const institutionDisabled = institutionError?.errorMessage === "404: Data could not be found";
  const eventCategory = gaImplementationMethodCategory(embedded, isFromSSO, isOnDeepLinkPage);

  // ********* USE-EFFECT HOOKS *********
  // Initial page load useEffect. Load institution information from the API first.
  useEffect(() => {
    // Initialize Google Analytics
    initialize();

    console.log("logging GA event:", {
      embedded
    });

    trackEvent(actions.IMPLEMENTATION_METHOD, eventCategory, { label: institutionId });

    // dynamically load digital-wallets for non-embed scenario
    if (!embedded) {
      const digitalWalletsId = "swivel-pay-digital-wallets";
      const walletsScript = document.createElement("script");
      walletsScript.type = "text/javascript";
      walletsScript.id = digitalWalletsId;
      walletsScript.src = REACT_APP_DIGITAL_WALLETS_PATH;
      document.head.appendChild(walletsScript);
    }

    if (queryToken && !isOnDeepLinkPage) {
      dispatch(setAuthToken(queryToken));
    }
    if (isFromSSO) {
      dispatch(getInstitutionDetails({
        institutionId,
        token: queryToken
      }))
        .unwrap()
        .then(() => {
          setInstitutionDetailsLoaded(true);
        })
        .catch(() => { });

      dispatch(getUser({
        userId,
        fromSSO: isFromSSO,
        token: queryToken
      }));
    }

    // check if institutionId from the query params is valid
    if (isUuidValid(institutionId) && config === null) {
      setSiteIsLoading(true);
      // call API to get institution information
      dispatch(getInstitution(institutionId))
        .unwrap()
        .then(() => {
          setInstitutionLoaded(true);
        })
        .catch(() => { });

      // call API to get maintenance information
      dispatch(getMaintenance(institutionId))
        .unwrap()
        .then(() => {
          setMaintenanceCallSuccess(true);
        })
        .catch(() => { });
    } else if (isUuidValid(institutionId)) {
      dispatch(getInstitution(institutionId))
        .unwrap()
        .then(() => {
          setInstitutionLoaded(true);
          setSiteIsLoading(false);
          dispatch(setLoadingState(false));
        })
        .catch(() => { });
    } else if (!config) {
      setSiteIsLoading(false);
      dispatch(setLoadingState(false));
      navigate("/404");
    }
  }, []);

  useEffect(() => {
    if (isFromSSO && isOnAccountsPage && !institutionDetailsLoaded) {
      return;
    }
    if (institutionLoaded && maintenanceCallSuccess) {
      setSiteIsLoading(false);
    }
  }, [institutionLoaded, maintenanceCallSuccess, institutionDetailsLoaded, isFromSSO, isOnAccountsPage]);

  // UseEffect for displaying the loading messages
  useEffect(() => {
    if (!isSiteLoading) {
      return;
    }

    if (isInstitutionLoading || isInstitutionDetailsLoading) {
      setTimeout(() => { setLoadingMessage(LOADING_FI_DATA); }, 10);
      return;
    }

    if (isMaintenanceLoading) {
      setTimeout(() => { setLoadingMessage(LOADING_MW_DATA); }, 10);
      return;
    }

    dispatch(setLoadingState(false));
    setLoadingMessage("");
    setSiteIsLoading(false);
  }, [isSiteLoading, isInstitutionLoading, isInstitutionDetailsLoading, isMaintenanceLoading]);

  // if isModalTimerActive, then create a timeout to open the modal at the end of the timeout
  // also clear the timeout timer handle in progress
  useEffect(() => {
    let timeout;
    if (isTimeoutTimerActive) {
      timeout = setTimeout(() => {
        dispatch(setIsTimeoutTimerActive(false));
        dispatch(setIsTimeoutModalOpen(true));
      }, Number(REACT_APP_MODAL_REFRESH_TIMER) * ONE_SECOND);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isTimeoutTimerActive]);

  useEffect(() => {
    // Sends page events to Google Analytics
    pageView(institutionId);
  }, [currentLocation]);

  useEffect(() => {
    if (isOnAccountsPage && !isOnConfirmationPage && !isPaymentSuccessful) {
      navigate("/accounts");
    }
  }, [navigate, isPaymentSuccessful, currentLocation, isOnConfirmationPage, isOnAccountsPage]);


  useEffect(() => {
    if (user.from_sso) {
      setRedirectionPath(paths.ssoTimeout);
    } else if (config?.express_pay_only) {
      setRedirectionPath(paths.express);
    } else {
      setRedirectionPath(paths.login);
    }
  }, [user.from_sso, config?.express_pay_only, paths.express, paths.login, paths.ssoTimeout]);

  return (
    <ThemeProvider theme={useMaterialTheme(validatedConfig, details)}>
      <NavigationManager />
      {
        isTimeoutModalOpen
          ? <TimeoutModal config={config} redirectionPath={redirectionPath} />
          : null
      }
      {
        institutionDisabled
          ? <ErrorPage
            title={"Unavailable"}
            subTitle={"We apologize for the inconvenience, but this payment service is not available. "
              + "Please contact your financial institution for further assistance."}
          />
          : <Layout
            isSiteLoading={isSiteLoading}
            loadingMessage={loadingMessage}
            shouldClearBreadcrumbs={shouldClearBreadcrumbs}
            redirectionPath={redirectionPath}
            setErrorPageLoaded={setErrorPageLoaded}
            timeoutMessage={timeoutMessage}
          />
      }
    </ThemeProvider >
  );

};

export default App;
