// Default Verbiage
export const APP_NAME = "Consumer Pay";
export const AGREE_TERMS = "Please agree to the Terms of Service.";
export const MAINTENANCE_TITLE = "Temporarily Offline for Maintenance";
export const NO_CONNECTION_ERR = "A connection error occurred. Please try back later or contact the financial institution for assistance.";
export const UNKNOWN_ERR = "Unknown error has occurred, try again later.";
export const MULTIPLE_FORM_ERROR_MESSAGE = "Several errors on the form, please fix the errors for any input below.";

// Account & Payment Messages
export const DEFAULT_CARDS = ["Amex", "Discover", "Mastercard", "Visa"];
export const LOAN_RESET_WARN = "Loan info change detected. Please select a loan to make a payment on to proceed forward.";
export const NO_ACCOUNTS_INFO = "No accounts found.";

// Session Timeout Messages
export const SESSION_TIMEOUT = "Session timed out due to inactivity.";
export const TIMEOUT_MODAL_TITLE = "Still there?";
export const TIMEOUT_ERR_MSG = "An error occurred in requesting to continue session.";

// Error 404 Messages
export const ERR_404_TITLE = "404";
export const ERR_404_SUBTITLE = "Sorry, that page was not found";

// Loading Messages
export const LOADING_PAGE = "Loading Page...";
export const LOADING_FI_DATA = "Loading financial institution data...";
export const LOADING_MW_DATA = "Loading maintenance data...";

// Routes
export const NO_AUTH_LOCATIONS = ["/", "/express-pay", "/login", "/register"];
export const PAGES_WITHOUT_CRUMBS = ["/", "/login", "/accounts", "/profile", "/history", "/register/guest"];
export const BACK_TO_LOGIN = ["/check-email", "/success/password-reset", "/success/registration"];

// Flows
export const EXPRESS_PAY = "express-pay";
export const REGISTERED = "registered";
export const REGISTER_NEW_USER = "register-new-user";

// Time based values
export const ONE_SECOND = 1000;  // 1 second = 1000 millisecond

export const PAYMENTS_TIME_ZONE = "America/Chicago";

// Pay to types
export const LOAN = "Loan";
export const ACCOUNT = "Account";
